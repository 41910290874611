:root {
    --light-blue: #9cd6d6;
    --transparent-green: #daf4e5;
    --blue: #387fba;
    --dark-blue: #2d6797;
    --greenish-blue: #4ecdc4;
    --greenish-blue-hover: #33b5ac;
    --green: #44c767;
    --green-hover: #5cbf2a;
    --red: #F26B6B;
    --red-hover: #e03134;
    --black: #333;
    --gray: #00000026;
    --lighter-gray: #f7f7f7;
}

* {-webkit-font-smoothing: antialiased;}
@font-face {
    font-family: ProximaNova;
    src: url('/assets/fonts/ProximaNova-Regular.woff');
  }

  @font-face {
    font-family: ProximaNova-Extrabld;
    src: url('/assets/fonts/ProximaNova-Extrabld.woff');
  }

  @font-face {
    font-family: ProximaNova-Bold;
    src: url('/assets/fonts/ProximaNova-Bold.woff');
  }

  @font-face {
    font-family: ProximaNova-Black;
    src: url('/assets/fonts/ProximaNova-Black.woff');
  }

  @font-face {
    font-family: ProximaNova-Light;
    src: url('/assets/fonts/ProximaNova-Light.woff');
  }

  @font-face {
    font-family: ProximaNova-Semibold;
    src: url('/assets/fonts/ProximaNova-Semibold.woff');
  }

  @font-face {
    font-family: ProximaNovaT-Thin;
    src: url('/assets/fonts/ProximaNovaT-Thin.woff');
  }

  @font-face {
    font-family: Rajdhani-Bold;
    src: url('/assets/fonts/Rajdhani-Bold.ttf');
  }

  @font-face {
    font-family: Rajdhani-Light;
    src: url('/assets/fonts/Rajdhani-Light.ttf');
  }

  @font-face {
    font-family: Rajdhani-Medium;
    src: url('/assets/fonts/Rajdhani-Medium.ttf');
  }

  @font-face {
    font-family: Rajdhani-Regular;
    src: url('/assets/fonts/Rajdhani-Regular.ttf');
  }

  @font-face {
    font-family: Rajdhani-Semibold;
    src: url('/assets/fonts/Rajdhani-Semibold.ttf');
  }
body {
    background-color: #387fba1f;
    color: var(--black);
    font-family: "Roboto","Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif;
}

main {
    margin-top: 66px;
}

.graficos {
    margin-bottom: 120px;
}

.navbar {
    background-color: var(--blue);
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
}

/* notificações */

.navbar-toggler.bell {
    border: none;
    font-size: 25px;
    outline: none !important;
    transition: all 1s;
}

.navbar-toggler.bell.new i {
    font-weight: 800;
    transition: all 1s;
}

.badge-notification {
    display: none;
}

.navbar-toggler.bell.new .badge-notification {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: var(--green);
    color: #fff;
    font-size: 0.8rem;
    border-radius: 50%;
}

.notifications {
    width: 100%;
    margin-top: 10px;
    padding: 0;
}

.notifications.new .triangle {
    background-color: var(--transparent-green);
}

.notifications .nav-item:not(:last-child) {
    border: 0px;
    border-style: solid;
    border-bottom-width: 1px;
    -moz-border-image: -moz-linear-gradient(right, white, var(--gray), var(--gray), white) 1 20%;
    -o-border-image: -o-linear-gradient(right, white, var(--gray), var(--gray), white) 1 20%;
    border-image: linear-gradient(to right, white 0%, var(--gray) 40%, var(--gray) 60%, white 100%) 1 20%;
}

.notifications .nav-item.new {
    background-color: var(--transparent-green);
    font-weight: 600;
}

.notifications .nav-link {
    color: var(--black);
    padding: .7rem 1rem;
}

.triangle {
    position: absolute;
    top: -8px;
    right: 15px;
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    background: #fff;
    border-radius: 6px 0px 0px 0px;
    border: 1px solid rgba(0,0,0,.15);
    border-right: none;
    border-bottom: none;
}

#navbarTogglerNotifications {
    background-color: #fff;
    color: #000;
}

/* dropdown de período */

.graph-options .dropdown-toggle {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}

.graph-options .dropdown-menu {
    background-color: var(--blue);
    margin: -.17rem 0 0 -0.6px;
    border-radius: 0 .25rem .25rem .25rem;
    border-top: 0;
    transform: translate3d(0px, 38px, 0px) !important;
}

.graph-options .dropdown-item {
    color: #fff;
}

.graph-options .dropdown-item.active, .graph-options .dropdown-item:active {
    background-color: var(--dark-blue);
}

/* área do gráfico */

.graph {
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    margin: 10px;
    padding: 10px;
    min-height: 200px;
}

.graph-options {
    margin: 10px;
}

.graph-options .btn-group {
    width: 100%;
    margin-bottom: 5px;
}

/* dropdown tipo do gráfico */

#dropdownMenuType {
    font-size: 1.1rem;
    font-weight: 400;
    box-shadow: none !important;
    outline: none !important;
    padding: .375rem 0 .5rem;
}

#dropdownMenuType::after {
    margin-left: .5rem !important;
}

.graph .dropdown-menu {
    padding: 0;
}

.graph .dropdown-item.active {
    color: var(--blue);
    background-color: #fff;
}

/* total do gráfico */

.graph-total {
    padding: .5rem 0;
    float: right;
    font-size: 0.85rem;
    font-weight: 600;
}

.graph-total span {
    padding: .25rem;
}

.graph-total .money {
    color: var(--green);
    border-color: var(--green);
}

.graph-total .appointment {
    color: var(--blue);
    border-color: var(--blue);
}

/* botões */

.btn {
    font-weight: 500;
    outline: none !important;
}

#btn-main-menu {
    box-shadow: none !important;
    outline: none !important;
}

.graph-options.row {
    margin-right: 0;
}

.graph-options .col {
    padding: 0;
    margin-right: 10px;
}

.btn-secondary {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
}

.btn-group .btn-secondary:not(.active) {
    background-color: var(--lighter-gray);
    color: var(--dark-blue);
    border-color: var(--lighter-gray);
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle, .btn-secondary:hover {
    background-color: var(--blue);
    border-color: var(--blue);
    box-shadow: 0px 0px 10px 0px #0000004a;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus, .btn-secondary:hover {
    box-shadow: none;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: var(--dark-blue);
}

/* botões flutuantes */

.floating-btns{
    position: fixed;
    bottom: 60px;
    right: 10px;
    z-index: 4;
    padding: 0;
}

.floating-btn {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 1.3rem;
    background-color: var(--blue);
    color: #fff;
}

.floating-btn:not(:last-child) {
    margin-right: 10px;
}

.floating-btn:focus, .floating-btn:hover {
    color: #fff;
}

/* modal */

.modal-header, .modal-footer {
    border: none;
}

#includeData .modal-body {
    padding: 0 1rem;
}

.form-group {
    margin-bottom: .5rem;
}

.incluir-atendimento .form-group:last-child {
    margin: 0;
}

.form-group label {
    margin-bottom: .2rem;
}

.add-picture-btn {
    background-color: var(--gray);
    font-size: 1.5rem;
}

/* alertas */

.alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 10px;
}

.alert.show {
    z-index: 5;
}

.fade:not(.show) {
    display: none;
}

#semDados {
    bottom: 50px;
    top: auto;
}

/* informações detalhadas */

.detailed-info {
    margin-right: 10px;
    margin-left: 10px;
}

.resumo {
    font-size: 1.2rem;
    text-align: center;
    color: var(--dark-blue);
}

.date {
    font-size: .9rem;
    margin-bottom: 0;
    background-color: #fff;
    font-weight: 500;
}

.toggle .graph-total {
    padding: 0;
    margin-right: 23px;
}

.patient {
    padding-left: 8px;
}

.patient-name {
    font-size: .9rem;
    font-weight: 400;
    margin-bottom: 0;
}

.patient .toggle-content {
    font-size: .8rem;
    margin-left: 15px;
    border-left: 1px solid #000;
    border-image: linear-gradient(to right, white 0%, #3a3a3a 40%, #3a3a3a 60%, white 100%) 1 20%;
}

.patient .toggle-content p {
    margin-bottom: .2rem;
    font-size: .85rem;
    padding-left: 15px;
    font-weight: 300;
}

.patient .toggle-content p:last-child {
    margin-bottom: .3rem;
}

.price {
    float: right;
    margin-right: 28px;
}

.price .fa-camera {
    font-size: 1.2rem;
    color: var(--dark-blue);
}

/* footer menu */

.footer-menu {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 3rem;
    background-color: #fff;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    box-shadow: 0px 3px 6px 0px var(--blue);
}

.footer-menu li {
    width: 33.33333%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-menu .nav-link {
    color: var(--blue);
}

.footer-menu i {
    font-size: 1.5rem;
}

#loading {
    display: none;
    z-index: 10;
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3c81b8;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #4bc56b ;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3c81b8;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}