.user-info {
    margin: 0 20px;
}

.user-avatar {
    border-radius: 50%;
    border: 3px solid var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin: 1rem auto;
}

#imagem {
    object-fit: cover;
    width: 114px;
    height: 114px;
    border-radius: 50%;
}

.fa-user-md {
    font-size: 60px;
    color: gray;
}

.progress {
    background-color: transparent;
}

.user-info .col {
    display: flex;
}

#userName {
    outline: none !important;
    box-shadow: none !important;
    border: none;
    color: var(--blue);
    background-color: transparent;
    font-weight: 500;
}

#userName:focus {
    background-color: #ffffff66;
}

.fa-pencil-alt {
    color: var(--blue);
    align-self: center;
    margin-left: 12px;
}

#userEmail {
    outline: none !important;
    box-shadow: none !important;
    border: none;
    background-color: transparent;
    color: gray;
    font-weight: 500;
}

.btn-contract {
    width: 100%;
    margin: 0 15px 1rem;
    border: 2px solid var(--blue);
    color: var(--blue) !important;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
}

.fa-file-pdf {
    color: var(--red);
    margin-left: 5px;
}

.renew {
    color: gray;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.btn-password {
    background-color: transparent;
    color: var(--blue) !important;
    padding-left: 0;
    box-shadow: none !important;
    display: block;
}

.btn-exit {
    background-color: transparent;
    color: var(--red) !important;
    padding-left: 0;
    box-shadow: none !important;
    display: block;
}

.btn-save {
    background-color: var(--blue);
    color: #fff;
    display: flex;
    margin-top: 1rem;
}