main#sobre {
    text-align: center;
    margin: 66px .5rem 55px;
    padding: 1rem;
    background-color: #fff;
    border-radius: .25rem;
}

main#sobre p, main#sobre ul, main#sobre h2 {
    text-align: left;
    font-size: 0.9rem;
}

main#sobre h2 {
    color: var(--dark-blue);
    font-size: 1.5rem;
}

main#sobre ul {
    list-style-type: circle;
}

main#sobre p a {
    color: var(--blue);
    word-break: break-all;
}

main#sobre .webmed-logo {
    width: 80%;
    margin: 1rem 1rem 1.5rem;
}

main#sobre a.brainsides {
    color: var(--black);
}